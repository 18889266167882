import { Button, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import {
  useCreatePinnedEntityMutation,
  useDeletePinnedEntityMutation,
  useGetPinnedEntitiesForProperty,
} from "../../lib/api/pinned-entities/hooks";
import { UserPinnedEntityType } from "../../lib/api/types/UserPinnedEntity";
import { PinIcon } from "../icons/PinIcon";
import { PinnedIcon } from "../icons/PinnedIcon";
import { Loader } from "../loading/Loader";

interface PinButtonProps {
  entityId: string;
  entityType: UserPinnedEntityType;
  propertyId: string;
  color?: string;
  size?: number;
}

export const PinButton = ({ entityId, entityType, propertyId, color, size = 24 }: PinButtonProps) => {
  const [isMutating, setIsMutating] = useState(false);
  const { pinnedEntities, isLoading } = useGetPinnedEntitiesForProperty(propertyId);
  const pinnedArea = pinnedEntities?.find((pinnedEntity) => pinnedEntity.entityId === entityId);
  const pinAreaMutation = useCreatePinnedEntityMutation();
  const unpinAreaMutation = useDeletePinnedEntityMutation();

  const loading = isLoading || isMutating;

  return (
    <Tooltip label={pinnedArea ? "Unpin from Property Preview" : "Pin to Property Preview"}>
      <Button
        variant="ghost"
        onClick={async (e) => {
          setIsMutating(true);
          try {
            if (!pinnedArea) {
              await pinAreaMutation.mutateAsync({ entityId, entityType, propertyId });
            } else {
              await unpinAreaMutation.mutateAsync({ pinnedEntity: pinnedArea });
            }
          } catch (e) {
            // Errors handled by the mutation
          }
          setIsMutating(false);
        }}
        disabled={loading}
        flexShrink={0}
      >
        {loading && <Loader size="sm" width="13px" color={color} />}
        {!loading && (
          <>
            {!pinnedArea && <PinIcon color={color} />}
            {pinnedArea && <PinnedIcon />}
          </>
        )}
      </Button>
    </Tooltip>
  );
};
